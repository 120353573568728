import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import spaces from "../../styles/theme/spaces";
import { MQ, typographyToCss } from "../../styles/helpers";
import type { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import { productCarouselColorTheme } from "./personalized-product-list-helper";
import type { ColorTheme } from "@xxl/content-api";

type PersonalizedWrapperProps = {
  carouselType: RecommendationsParametersStrategyEnum;
  colorTheme?: ColorTheme;
  isNextApp?: boolean;
};

export const SeeAllProductsButton = styled.a`
  margin-top: ${spaces.large};
  width: 100%;

  ${MQ("smallTablet")} {
    width: fit-content;
    margin: ${spaces.large} auto 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 70px;
`;

export const HeaderText = styled.h3`
  margin: 0 0 ${spaces.smallRegular};
  overflow-wrap: anywhere;

  ${MQ("laptop")} {
    margin: 0 0 ${spaces.large};
  }
`;

export const Link = styled.a<PersonalizedWrapperProps>(
  ({ carouselType, colorTheme }) => css`
    display: flex;
    align-items: center;
    min-width: 100px;
    justify-content: flex-end;
    margin-bottom: ${spaces.mini};
    ${productCarouselColorTheme(carouselType, colorTheme)};
    background-color: transparent;

    ${MQ("tablet")} {
      width: fit-content;
    }
  `
);

export const LinkDisplayName = styled.span`
  margin-right: ${spaces.micro};
  text-align: right;
  ${typographyToCss({
    fontFamily: "var(--font-family-bold)",
    fontSize: 15,
  })}
`;

export const PersonalizedWrapper = styled.div<PersonalizedWrapperProps>(
  ({ carouselType, colorTheme, isNextApp = false }) => css`
    //padding is related to the global class - container
    padding: ${spaces.smallRegular} ${spaces.smallRegular};
    ${productCarouselColorTheme(carouselType, colorTheme)}

    ${MQ("tabletHorizontal")} {
      ${isNextApp &&
      css`
        padding: 54px ${spaces.huge};
      `}
    }

    ${MQ("laptop")} {
      padding: ${spaces.almostHuge} ${spaces.mediumHuge};

      ${isNextApp &&
      css`
        padding: 54px ${spaces.huge};
      `}
    }

    ${MQ("bigDesktop")} {
      padding: 54px calc((100% - 1332px) / 2);
    }
  `
);

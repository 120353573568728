import * as React from "react";
import { createPortal } from "react-dom";
import { ProductList } from "./ProductList";
import type { ProductRecommendationsProps } from "./ProductList";
import { addLoginEventListener } from "../../utils/xxl-event";
import {
  hideRecommendations,
  fetchData as fetchDataFunc,
  getProductRecommendationsFromElement,
  createRecomendationsProps,
} from "./personalized-product-list-helper";
import type {
  Recommendation,
  PersonalizedData,
} from "./personalized-product-list-helper";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useEffect, useState } from "react";
import { useSharedData } from "../../contexts/SharedData";
import { Configuration, RecommendationsApi } from "@xxl/recommendations-api";

export type WrapperProps = {
  rootElements: NodeListOf<HTMLDivElement>;
};

const GetRecommendationsData = (
  recommendations: Recommendation[]
): ProductRecommendationsProps[] => {
  const [personalizedData, setPersonalizedData] =
    React.useState<PersonalizedData>();
  const { configuration, siteUid } = useSharedData().data;
  const [recommendationsApi] = React.useState(
    new RecommendationsApi(new Configuration(configuration.recommendationsApi))
  );

  React.useEffect(() => {
    const fetchData = fetchDataFunc(
      recommendations,
      setPersonalizedData,
      recommendationsApi,
      siteUid
    );
    void fetchData();
    addLoginEventListener(fetchData);
  }, [recommendations, recommendationsApi, siteUid]);

  React.useEffect(() => {
    if (personalizedData?.length === 0) {
      hideRecommendations(recommendations);
    }
  }, [personalizedData, recommendations]);

  return recommendations.map((recommendation) => {
    const { recommendationsKey } = recommendation;
    const products = personalizedData?.find(
      ({ key }) => key === recommendationsKey
    )?.productList;
    return {
      products,
      ...recommendation,
      carouselType: recommendation.strategy,
    };
  });
};

export const PersonalizedProductListWrapper: React.FunctionComponent<
  WrapperProps
> = ({ rootElements }) => {
  const { t } = useTranslations();
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [personalizedData, setPersonalizedData] = useState<PersonalizedData>(
    []
  );
  const [productRecomendationsProps, setproductRecomendationsProps] = useState<
    ProductRecommendationsProps[]
  >([]);
  const { configuration, siteUid } = useSharedData().data;
  const [recommendationsApi] = React.useState(
    new RecommendationsApi(new Configuration(configuration.recommendationsApi))
  );

  useEffect(() => {
    const recommendationsFromElement = [...rootElements].map((element) =>
      getProductRecommendationsFromElement(element, t)
    );
    setRecommendations(recommendationsFromElement);
  }, [rootElements, t]);

  useEffect(() => {
    if (recommendations.length === 0) {
      return;
    }
    const fetchData = () => {
      void fetchDataFunc(
        recommendations,
        setPersonalizedData,
        recommendationsApi,
        siteUid
      )();
    };
    fetchData();
    document.body.addEventListener("xxl-login-event", fetchData);

    return () => {
      document.body.removeEventListener("xxl-login-event", fetchData);
    };
  }, [recommendations, recommendationsApi, siteUid]);

  useEffect(() => {
    if (personalizedData.length === 0 || recommendations.length === 0) {
      setproductRecomendationsProps([]);
      return;
    }
    setproductRecomendationsProps(
      createRecomendationsProps(recommendations, personalizedData)
    );
  }, [recommendations, personalizedData]);

  return (
    <>
      {productRecomendationsProps.map((props) =>
        props.element !== undefined
          ? createPortal(<ProductList {...props} />, props.element)
          : null
      )}
    </>
  );
};

export const PersonalizedProductList: React.FunctionComponent<{
  recommendations: Recommendation[];
}> = ({ recommendations }) => {
  const recommendationsWithData = GetRecommendationsData(recommendations);
  return (
    <>
      {recommendationsWithData.map((props, index) => (
        <ProductList key={index} {...props} />
      ))}
    </>
  );
};
